import React from "react";
import "./styles.scss";
import DeppaneurCard from "../depanneurcard";
import { useSelector } from "react-redux";

export default function SwipeComp({depList, onChooseDepanneur}) {
  // const { listDeps } = useSelector((state) => state.user)
  return (
    <div className="container-swipe">
      <div className="mini-cont">
        {
          depList?.length > 0 ?
          depList?.map((item, index) => (
            <React.Fragment key={index}>
              <DeppaneurCard 
                data={item} 
                onChoose={() => onChooseDepanneur(item.id)}
              />
            </React.Fragment>
          ))
          :
          "Il n'y a pas de dépanneur à proximité"
        }
      </div>
    </div>
  );
}
