import React from 'react'
import './styles.scss';
import {DeppaneurImg, MarkerImg, Star, UserPng} from '../../assets/images';
import {Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IS_RATE_DRAWER } from '../../constants/actions';
import { PICS_BASE_URL } from '../config/keys';
import { apiClient } from '../../actions/api';
import moment from 'moment';

export default function DeppaneurCard({data, onChoose}) {
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch(); 
    const handleNav =(e)=>{
        e.preventDefault() ; 
        // dispatch({type:IS_RATE_DRAWER,payload:true})
    }
    const handleCallDep = (e) => {
        e.preventDefault();
        const params = {
            caller_id: user?.user?.id,
            receiver_id: data?.id,
            call_time: moment().format('YYYY-MM-DD')
        }
        apiClient()
        .post(`/call-history/create`, params)
        .then((res) => {
        if (res.data.success) {
            // Detect platform
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            
            // Format phone number based on platform
            let phoneNumber = data?.phoneNumber || '';
           
            phoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
            
            
            const phoneLink = `tel:${phoneNumber}`;
            window.location.href = phoneLink;
        } 
        });
        
    }

    return (
        <div className='deppaneur-card'>
            <div className='depp-card-body'>
                <div className='depp-card-head'>
                    <img src={
                  data?.dep_picture
                    ? `${PICS_BASE_URL}/${data?.dep_picture}`
                    : UserPng
                } id='dep-img'alt='img-depp' />
                    
                    <div className='infos-div'>
                        <span>{data?.service_name == '' ? 'N/A' : data?.service_name}</span>
                        <div className='rate-part'>
                            <img src={Star}/>
                            <span>{data?.rate}</span>
                        </div>
                        <Link
                        onClick={(e)=>handleNav(e)}
                        >
                            Voir les notes
                        </Link>
                    </div>
                </div>
                <div className='middle-card'>
                    <img src={MarkerImg} alt='' /> 
                    <span>{data?.distance?.toFixed(0)} Km</span>
                </div>
                <div className='button-group'>
                    <button
                        className='btn depp-btn'
                        onClick={(e) => handleCallDep(e)}
                    >
                        Appeler
                    </button>
                    <button
                        className='btn depp-btn'
                        onClick={onChoose}
                    >
                        Choisir
                    </button>
                </div>
            </div>
        </div>
    )
}
