import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, useJsApiLoader, MarkerF, DirectionsRenderer } from "@react-google-maps/api";
import { apiClient } from "../../actions/api";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { toast } from 'react-toastify';
import { googleMapsConfig } from '../../config/googleMapsConfig';

export default function ActiveRequestDepanneur() {
  const [assignment, setAssignment] = useState(null);
  const [directions, setDirections] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [locationError, setLocationError] = useState(null);
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false);

  const { isLoaded } = useJsApiLoader(googleMapsConfig);

  useEffect(() => {
    setIsLoading(true);
    apiClient()
      .get(`/assignment/${id}`)
      .then((res) => {
        if (res.data.success) {
          setAssignment(res.data.assignment);
        }
      })
      .catch(error => {
        console.error("Error fetching assignment:", error);
        //toast.error("Erreur lors de la récupération des données");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  // Update directions when we have both locations
  const updateDirectionsWithNewLocation = useCallback((depLocation) => {
    if (!window.google || !assignment) return;

    const directionsService = new window.google.maps.DirectionsService();
    const origin = {
      lat: depLocation.lat,
      lng: depLocation.lng
    };
    const destination = {
      lat: Number(assignment.client_location_lat),
      lng: Number(assignment.client_location_lng)
    };

    directionsService.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK") {
          setDirections(result);
        } else {
          console.error("Directions request failed:", status);
          //toast.error("Erreur lors du calcul de l'itinéraire");
        }
      }
    );
  }, [assignment]);

  // Update DB location
  const updateLocationInDB = useCallback((location) => {
    apiClient()
      .post(`/assignment/update-depanneur-location/${id}`, {
        lat: location.lat,
        lng: location.lng
      })
      .then(() => {
        console.log("DB location updated");
      })
      .catch(error => {
        console.error("Error updating location in DB:", error);
      });
  }, [id]);

  // Improve getCurrentLocation with better error handling and status tracking
  const getCurrentLocation = useCallback((shouldUpdateDB = false) => {
    console.log('Getting current location, updateDB:', shouldUpdateDB); // Debug log
    if (!navigator.geolocation) {
      setLocationError("Géolocalisation non supportée par votre navigateur");
      return;
    }

    setIsUpdatingLocation(true);
    
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const newLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        setCurrentLocation(newLocation);
        updateDirectionsWithNewLocation(newLocation);
        setLocationError(null);
        
        if (shouldUpdateDB) {
          updateLocationInDB(newLocation)
            .catch(error => {
              console.error("Failed to update location in DB:", error);
              //toast.error("Erreur de mise à jour de la position");
            });
        }
      },
      (error) => {
        console.error("Geolocation error:", error);
        let errorMessage = "Erreur de géolocalisation";
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage = "Accès à la localisation refusé";
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = "Position non disponible";
            break;
          case error.TIMEOUT:
            errorMessage = "Délai d'attente dépassé";
            break;
        }
        setLocationError(errorMessage);
        //toast.error(errorMessage);
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 5000
      }
    ).finally(() => {
      setIsUpdatingLocation(false);
    });
  }, [updateDirectionsWithNewLocation, updateLocationInDB]);

  // Improve WebView location handling
  const handleMobileLocation = useCallback((event) => {
    console.log('Received mobile location:', event.detail); // Debug log
    try {
      const location = event.detail;
      // More lenient validation that converts strings to numbers
      if (!location || !location.lat || !location.lng) {
        throw new Error('Invalid location data received');
      }

      const newLocation = {
        lat: Number(location.lat),
        lng: Number(location.lng)
      };
      
      if (isNaN(newLocation.lat) || isNaN(newLocation.lng)) {
        throw new Error('Invalid coordinates received');
      }
      
      setCurrentLocation(newLocation);
      updateDirectionsWithNewLocation(newLocation);
      updateLocationInDB(newLocation)
        .catch(error => {
          console.error("Failed to update location in DB:", error);
          //toast.error("Erreur de mise à jour de la position");
        });
      
      setLocationError(null);
    } catch (error) {
      console.error("Error handling mobile location:", error);
      //setLocationError("Erreur lors de la réception de la position");
      //toast.error("Erreur de localisation");
    }
  }, [updateDirectionsWithNewLocation, updateLocationInDB]);

  // Improve location tracking useEffect
  useEffect(() => {
    const isInWebView = !!window.ReactNativeWebView;
    let markerInterval;
    let dbInterval;

    if (isInWebView) {
      // WebView location handling
      window.addEventListener('mobileLocation', handleMobileLocation);
      
      // Request initial location
      try {
        // Add periodic location requests for WebView
        window.postMessage('getLocation');
        
        // Add intervals for WebView too
        markerInterval = setInterval(() => {
          window.postMessage('getLocation');
        }, 10000);
        
        dbInterval = setInterval(() => {
          window.postMessage('getLocation');
        }, 20000);
        
      } catch (error) {
        console.error("Error requesting location from WebView:", error);
      }
      
      return () => {
        window.removeEventListener('mobileLocation', handleMobileLocation);
        // Clear intervals on cleanup
        if (markerInterval) clearInterval(markerInterval);
        if (dbInterval) clearInterval(dbInterval);
      };
    } else {
      // Web browser location handling
      getCurrentLocation(true);

      // More frequent updates for marker position (every 10 seconds)
      markerInterval = setInterval(() => {
        getCurrentLocation(false);
      }, 10000);

      // Less frequent updates for DB (every 20 seconds)
      dbInterval = setInterval(() => {
        getCurrentLocation(true);
      }, 20000);

      return () => {
        if (markerInterval) clearInterval(markerInterval);
        if (dbInterval) clearInterval(dbInterval);
      };
    }
  }, [getCurrentLocation, handleMobileLocation]);

  const handleBack = () => {
    history.goBack();
  };

  const handleCompleteService = () => {
    apiClient()
      .post(`/assignment/complete-service/${id}`)
      .then((res) => {
        if (res.data.success) {
          toast.success("Service terminé avec succès");
          history.push('/'); // or wherever you want to redirect
        }
      })
      .catch(error => {
        console.error("Error completing service:", error);
        //toast.error("Erreur lors de la finalisation du service");
      });
  };

  const handleCallClient = () => {
    if (assignment?.client_phone) {
      window.location.href = `tel:${assignment.client_phone}`;
    }
  };

  if (!isLoaded || isLoading) return <div>Loading...</div>;

  return (
    <div className="active-request-container">
      {locationError && (
        <div className="location-error-banner">
          {locationError}
        </div>
      )}
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100vh' }}
          center={currentLocation || (assignment ? {
            lat: Number(assignment.client_location_lat),
            lng: Number(assignment.client_location_lng)
          } : undefined)}
          zoom={15}
          options={{
            streetViewControl: false,
            zoomControl: true,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
        >
          {currentLocation && (
            <MarkerF
              key={`${currentLocation.lat}-${currentLocation.lng}`}
              position={currentLocation}
              label={{
                text: "D",
                color: "white",
                fontWeight: "bold"
              }}
            />
          )}

          {assignment && (
            <MarkerF
              position={{
                lat: Number(assignment.client_location_lat),
                lng: Number(assignment.client_location_lng)
              }}
              label={{
                text: "C",
                color: "white",
                fontWeight: "bold"
              }}
            />
          )}

          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#6a02c6",
                  strokeWeight: 5,
                },
              }}
            />
          )}
        </GoogleMap>
      </div>

      <div className="info-overlay">
        <button className="back-button" onClick={handleBack}>
          <UilAngleLeft size="24" color="#6a02c6" />
          Retour
        </button>
        
        {assignment && (
          <div className="status-card">
            <h2>En route vers le client</h2>
            <div className="status-details">
              <p>Client: {assignment.client_name}</p>
              <p>Adresse: {assignment.client_address}</p>
              <p>Distance: {directions?.routes[0]?.legs[0]?.distance?.text || 'Calcul...'}</p>
              <p>Temps estimé: {directions?.routes[0]?.legs[0]?.duration?.text || 'Calcul...'}</p>
            </div>
            <div className="action-buttons">
              <button 
                className="call-button"
                onClick={handleCallClient}
              >
                Appeler le client
              </button>
              <button 
                className="complete-button"
                onClick={handleCompleteService}
              >
                Terminer le service
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 