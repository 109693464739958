import React from "react";
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import { CustomRoute } from "../components";
import { 
     AuthPage,
     ChoosePage,
     CountrySelectPage,
     FilterPage,
     MainPage,
     OtpPage,
     PhonePage,
     WelcomePage,
     VhDetailsPage,
     ReservationPage,
     ReservStatusPage,
     AllReservPage,
     ShowOffrePage,
     GetDepPage,
     SelectPopUp,
     DoneReservPage,
     CallHistoryPage,
     MyinfosPage,
     UpdatePubPage,
     EmailPage,
     PasswordPage,
     PrivacyPage,
     ForgetPassPage
 } from "../pages";
import NamePage from "../pages/NamePage";
import ShowAllPage from "../pages/showallpage";
import ReservInfosPage from "../pages/reservinfopage";
import NewPubPage from "../pages/newpubpage";
import OffresPage from "../pages/offrespage";
import MyPubsPage from "../pages/mypubspage";
import ProfileDepp from "../pages/profiledepp";
import DeppInfoPage from "../pages/deppinfospage";
import SignInPage from "../pages/signinpage";
import ActiveRequestClient from "../pages/active-request-client";
import ActiveRequestDepanneur from "../pages/active-request-depanneur";
const MainNavigator =()=> {
    return (
        <Router >
            <Switch>
            <CustomRoute path='/myinfos' mobile headtext="Mes informations" component={MyinfosPage}/>
            <CustomRoute path='/signinpage' parts component={SignInPage}   />
            <CustomRoute path='/forget-pass' parts component={ForgetPassPage}   />
            <CustomRoute path='/callhistory' component={CallHistoryPage}   />
            <CustomRoute path='/deppinfos' component={DeppInfoPage}   />
            <CustomRoute path='/profiledepp' component={ProfileDepp}   />
            <CustomRoute path='/done' component={DoneReservPage} mobile headtext="Mes réservations" />
            <CustomRoute path='/selectpop/:vhtype' component={SelectPopUp} mobile headtext="Faire une réservation" />
            <CustomRoute path='/getdep' component={GetDepPage} /> 
            <CustomRoute path='/mypubs' mobile headtext="Mes annonces publiées" component={MyPubsPage}/>
            <CustomRoute path='/showoffre/:idReservation' mobile headtext="Offre reçue" component={ShowOffrePage}/>
            <CustomRoute path='/mesoffres' mobile headtext="Offres reçues" component={OffresPage}/>
            <CustomRoute path='/myreservations' special={true} mobile headtext="Mes réservations" component={AllReservPage}/>
            <CustomRoute path='/newpub/:step' mobile pub justheader component={NewPubPage}/>
            <CustomRoute path='/updatepub/:step' updatedpart={true} justheader mobile pub component={UpdatePubPage}/>
            <CustomRoute path='/reservstatus/:idReservation' mobile headtext="Mes réservations" component={ReservStatusPage}/>
            <CustomRoute path='/reserveinfos' mobile headtext="Mes réservations" component={ReservInfosPage}/>
            <CustomRoute path='/reservation/:id' mobile headtext="Mes réservations" hadno={true} component={ReservationPage}/>
            <CustomRoute path='/vhdetails/:id' mobile headtext="Détails de l'annonce" component={VhDetailsPage} />
            <CustomRoute path='/filter/:defvh' mobile headtext="Filtrer" component={FilterPage} />
            <CustomRoute path='/showall/:type' mobile headtext="Faire une réservation" component={ShowAllPage} />
            <CustomRoute path='/privacy' mobile headtext="RGPD" component={PrivacyPage} />
            <CustomRoute path='/choosepage' nav parts component={ChoosePage} />
            <CustomRoute path='/welcome' parts component={WelcomePage} />
            <CustomRoute path='/country' parts component={CountrySelectPage}/>
            <CustomRoute path='/name' parts component={NamePage}/>
            <CustomRoute path='/otp' parts component={OtpPage}/>
            <CustomRoute path='/email' parts component={EmailPage}/>
            <CustomRoute path='/password' parts component={PasswordPage}/>
            
            <CustomRoute path='/phone' parts component={PhonePage}/>
            <CustomRoute path="/auth" parts exact component={AuthPage} />
            <CustomRoute path="/" parts exact component={MainPage} />
            <CustomRoute path="/active-request-client/:id" parts exact component={ActiveRequestClient} />
            <CustomRoute path="/active-request-depanneur/:id" parts exact component={ActiveRequestDepanneur} />
            </Switch>
        </Router>
    );
}

export default MainNavigator;