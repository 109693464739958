import React, { useState, useCallback, useEffect } from "react";
import { Form } from "react-bootstrap";
import { UilAngleRightB, UilUserSquare, UilAngleRight, UilSignout, UilMapMarker, UilClipboardAlt } from "@iconscout/react-unicons";
import "./styles.scss";
import { EmailXd, MarkerImg, PhoneXd, Wathsapp } from "../../assets/images";
import { Link, useHistory } from "react-router-dom";
import { ChangePosComp } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATE_USER, IS_POS_DRAWER } from "../../constants/actions";
import { logoutFunct } from "../../actions/user";
import { PICS_BASE_URL } from "../../components/config/keys";
import { apiClient } from "../../actions/api";
import PlaceIcon from '@mui/icons-material/Place';
import axios from 'axios';
import { messaging } from '../../firebase-config';
import { toast } from 'react-toastify';
import { UilSync } from "@iconscout/react-unicons";

export default function ProfileDepp() {
  const { positiondepp, typechoice } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [deppstate, setdeppState] = useState(user?.user?.actif ? "Ouvert" : "Fermé");
  const [pendingAssignments, setPendingAssignments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let formData = {
    userId: user?.user?.id,
    actif: '',
  };

  const handledeppstate = (e) => {

    if (deppstate === "Ouvert") {
      setdeppState("Fermé");
      formData.actif = false
    } else {
      setdeppState("Ouvert");
      formData.actif = true
    }

    apiClient()
        .post('/update-statut', formData)
        .then((res) => {
          if(res.data.success){
            dispatch({ type: AUTHENTICATE_USER, payload: res?.data });
          }
        });
    
  };

  const handleNav = (goto) => {
    history.push("/" + goto);
  };
  const showPosDrawer = (e) => {
    e.preventDefault();
    
    // Check if running in WebView
    const isInWebView = !!window.ReactNativeWebView;

    if (isInWebView) {
      // Request location from mobile app
      window.postMessage('getLocation');
      
      // Add event listener for mobile location response
      const handleMobileLocation = (event) => {
        const location = event.detail;
        if (location) {
          let formData = {
            userId: user?.user?.id,
            lat: location.lat,
            lng: location.lng,
          };
          
          apiClient()
            .post('/update-geo-loc', formData)
            .then((res) => {
              if(res.data.success){
                dispatch({ type: IS_POS_DRAWER, payload: true });
              }
            });
          
          // Remove listener after handling location
          window.removeEventListener('mobileLocation', handleMobileLocation);
        }
      };

      window.addEventListener('mobileLocation', handleMobileLocation);
    } else {
      // Regular web browser location handling
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          let formData = {
            userId: user?.user?.id,
            lat: latitude,
            lng: longitude,
          };
          
          apiClient()
            .post('/update-geo-loc', formData)
            .then((res) => {
              if(res.data.success){
                dispatch({ type: IS_POS_DRAWER, payload: true });
              }
            });
        },
        (error) => {
          console.error("Error getting user's location:", error.message);
          // Handle error, if needed
        },
        options
      );
    }
  };
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      
      dispatch(logoutFunct());
      history.push('/');
    } catch (error) {
      console.error('Error removing token:', error);
    }
  };

  const fetchPendingAssignments = useCallback(() => {
    setIsLoading(true);
    apiClient()
      .get(`/assignment/pending-assignment/${user?.user?.id}`)
      .then((res) => {
        if (res.data.success) {
          // Ensure we always set an array, even if empty
          setPendingAssignments(res.data.assignments || []);
        } else {
          setPendingAssignments([]);
        }
      })
      .catch(error => {
        console.error("Error fetching Assignments:", error);
        toast.error("Erreur lors de la récupération des demandes");
        setPendingAssignments([]); // Set empty array on error
      })
      .finally(() => setIsLoading(false));
  }, [user?.user?.id]);

  const handleDemandAction = (assignmentid, action) => {
    apiClient()
      .post(`/assignment/update-status/${assignmentid}`, { status: action })
      .then((res) => {
        if (res.data.success) {
          toast.success(action === 'accepted' ? 
            "Demande acceptée avec succès" : 
            "Demande rejetée"
          );
          fetchPendingAssignments(); // Refresh the list
          
          if (action === 'accepted') {
            history.push(`/active-request-depanneur/${assignmentid}`);
          }
        }
      })
      .catch(error => {
        console.error("Error updating demand:", error);
        toast.error("Erreur lors de la mise à jour de la demande");
      });
  };

  // Fetch Assignments on component mount
  useEffect(() => {
    fetchPendingAssignments();
  }, [fetchPendingAssignments]);

  return (
    <>
      {positiondepp && <ChangePosComp />}
      <div className="profile-depp">
        <div className="profile-depp-head">
          <div className="kleever-log" onClick={() => history.push('/showall/'+typechoice)}></div>
        </div>
        <div className="setting-part">
          <div className="setting-part-head">
            <div
              className="profile-pic"
              style={{
                backgroundImage: `url('${PICS_BASE_URL}/${user?.user?.dep_picture == null ? 'images/avataruser.png' : user?.user?.dep_picture}')`,
              }}
            ></div>
            <span>{user?.user?.service_name == '' ? 'N/A' : user?.user?.service_name}</span>
            <div className="check-div">
              <Form.Check
                type="switch"
                id="custom-switch"
                onClick={(e) => handledeppstate(e)}
                checked={deppstate === "Fermé"}
              />
              <span>{deppstate}</span>
            </div>
          </div>
          <div className="setting-nav-part">
            <div className="nav-item" onClick={() => handleNav("deppinfos")}>
              <UilUserSquare size="40" color="gray" />
              <span>Mes informations</span>
              <UilAngleRightB size="25" color="white" />
            </div>
            <div className="nav-item" onClick={() => handleNav("callhistory")}>
              <UilUserSquare size="40" color="gray" />
              <span>Historique des appels</span>
              <UilAngleRightB size="25" color="white" />
            </div>
            <button
              className="btn refresh-pos"
              onClick={(e) => showPosDrawer(e)}
            >
              <PlaceIcon />
              Actualiser ma géolocalisation actuelle
            </button>
          </div>
          {/* Demands Section */}
          <div className="demands-container">
                      <div className="demands-header">
                            <div className="demands-title">
                              <UilClipboardAlt size="24" color="#6a02c6" />
                              <span>Demandes en attente</span>
                            </div>
                              <button 
                                className="refresh-button"
                                onClick={fetchPendingAssignments}
                                disabled={isLoading}
                              >
                                <UilSync className={isLoading ? 'spinning' : ''} color="#6a02c6" />
                              </button>
                      </div>
                      <div className="demands-list">
                        {pendingAssignments.length === 0 ? (
                          <div className="no-demands">
                            <p>Aucune demande en attente</p>
                          </div>
                        ) : (
                          pendingAssignments.map((demand) => (
                            <div key={demand.id} className="demand-card">
                              <div className="demand-info">
                                <div className="client-details">
                                  <p className="client-name">{demand.client_name}</p>
                                  <p className="client-address">{demand.client_address}</p>
                                </div>
                                {/* <p className="distance">{demand.distance} km</p> */}
                              </div>
                              <div className="demand-actions">
                                <button
                                  className="accept-btn"
                                  onClick={() => handleDemandAction(demand.id, 'accepted')}
                                >
                                  Accepter
                                </button>
                                <button
                                  className="reject-btn"
                                  onClick={() => handleDemandAction(demand.id, 'rejected')}
                                >
                                  Rejeter
                                </button>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                      </div>
            <div className="bottom-setting-part">
              <span>Besoin d'aide ? Contactez le service client !</span>
                <div className="contact-div">
                  <img src={PhoneXd} alt="phone" />
                  <img src={Wathsapp} alt="wathsapp" />
                  <img src={EmailXd} alt="email" />
                </div>
              <span className="logout" onClick={(e) => handleLogout(e)}>
                Se déconnecter
              </span>
            </div>
          </div>
        </div>
    </>
  );
}
