import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Bus,
  Car,
  Scooter,
  Truck,
  TruckHolder,
  BannerDepanneur,
} from "../../assets/images";
import { UilArrowLeft, UilArrowRight } from "@iconscout/react-unicons";
import { SideBar } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAnnonceListe, getAnnonceListe2, getAnnonceListe3, getAnnonceListe4 } from "../../actions/annonce";
import Loader from "react-js-loader";
import { typevhchoice } from "../../actions/global";
import {
  getTypeOfVh,
  getMarqueOfVh,
  getNbrPlaceOfVh,
  getModelOfVh,
  getSmallPriceOfVh,
  getBigPriceOfVh,
  getChauffeurOfVh,
  getBoiteVeOfVh,
} from "../../actions/annonce";
import { VH_MARQUE } from "../../constants/actions";

export default function ChoosePage() {
  const history = useHistory();
  const [vhindex, setVhindex] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const ChoiceData = [
    {
      title: "Dépanneur",
      image: TruckHolder,
      banner: BannerDepanneur,
      type: "depanneur",
    },
    {
      title: "Voiture",
      image: Car,
      banner: Banner3,
      type: "voiture",
    },
    {
      title: "Car / Bus",
      image: Bus,
      banner: Banner1,
      type: "bus",
    },
    {
      title: "Camion",
      image: Truck,
      banner: Banner4,
      type: "camion",
    },
    {
      title: "Moto",
      image: Scooter,
      banner: Banner2,
      type: "moto",
    },
  ];
  const handlevhindex = (c) => {
    switch (c) {
      case "-":
        if (vhindex === 0) {
          setVhindex(4);
        } else {
          setVhindex(vhindex - 1);
        }
        break;
      case "+":
        if (vhindex === 4) {
          setVhindex(0);
        } else {
          setVhindex(vhindex + 1);
        }
        break;
      default:
        break;
    }
  };

  const handleNav = (item) => {
    setLoading(true);
    
    if (item === "depanneur") {
      history.push("/getdep");
      setLoading(false);
      return;
    }

    dispatch(typevhchoice(item));

    // Reset all filters
    dispatch(getTypeOfVh(""));
    dispatch(getMarqueOfVh(""));
    dispatch(getNbrPlaceOfVh(""));
    dispatch(getModelOfVh(""));
    dispatch(getSmallPriceOfVh(0));
    dispatch(getBigPriceOfVh(0));
    dispatch(getChauffeurOfVh(null));
    dispatch({ type: VH_MARQUE, payload: '' });
    dispatch(getBoiteVeOfVh(""));

    // Send the appropriate request based on vehicle type
    switch (item) {
      case "voiture":
        dispatch(getAnnonceListe({ type_de_vh: item, user_id: user?.user?.id }))
          .then(() => history.push("/showall/" + item))
          .catch(() => setLoading(false));
        break;
      case "bus":
        dispatch(getAnnonceListe2({ type_de_vh: item, user_id: user?.user?.id }))
          .then(() => history.push("/selectpop/" + item))
          .catch(() => setLoading(false));
        break;
      case "camion":
        dispatch(getAnnonceListe3({ type_de_vh: item, user_id: user?.user?.id }))
          .then(() => history.push("/selectpop/" + item))
          .catch(() => setLoading(false));
        break;
      case "moto":
        dispatch(getAnnonceListe4({ type_de_vh: item, user_id: user?.user?.id }))
          .then(() => history.push("/showall/" + item))
          .catch(() => setLoading(false));
        break;
      default:
        setLoading(false);
        break;
    }
  };

  return (
    <div>
      {loading && (
        <Loader
          type="spinner-circle"
          bgColor="#000"
          color="#fff"
          size={100}
          className="absolute-center-loader"
        />
      )}
      <div className="container-fluid choose-container">
      <SideBar btn />

        <div
          className="choose-banner"
          style={{
            backgroundImage: `url(${ChoiceData[vhindex]?.banner})`,
          }}
        >
        </div>
        <div className="choose-control">
          <p>
            {ChoiceData[vhindex]?.type === "depanneur" ? (
              "Trouver un "
            ) : (
              `Louer ${
                ChoiceData[vhindex]?.type === "bus" ||
                ChoiceData[vhindex]?.type === "camion"
                  ? "un "
                  : "une "
              }`
            )}
            <span id="type-span">{ChoiceData[vhindex]?.title}</span>
          </p>
          <div className="swipe-case">
            <button className="btn">
              <UilArrowLeft size="15" onClick={() => handlevhindex("-")} />
            </button>
            <img
              className={ChoiceData[vhindex]?.type}
              src={ChoiceData[vhindex]?.image}
              alt=""
            />
            <button className="btn">
              <UilArrowRight size="15" onClick={() => handlevhindex("+")} />
            </button>
          </div>
          <button
            className="btn ok-btn"
            onClick={() => handleNav(ChoiceData[vhindex]?.type)}
          >
            {ChoiceData[vhindex]?.type === "depanneur" ? "Trouver un dépanneur" : "Confirmer"}
          </button>
        </div>
      </div>
    </div>
  );
}
