import { AUTHENTICATE_USER, IS_SUBMITED, IS_ERROR, LIST_DEP, INSC_CODE, INSC_NAME, INSC_PHONE, INSC_EMAIL, INSC_COUNTRY, INSC_PASSWORD } from "../constants/actions";
import { getMesAnnonce } from "./annonce";
import { apiClient } from "./api";
import FCMService from '../services/fcmService';


// this function allows to create a user in the database
export const registerUser = (params) => (dispatch) => {
  const data = {
    ...params,
  };

  return apiClient()
    .post(`/register`, data)
    .then((res) => {
      if (res.data.success === true) {
        dispatch({ type: AUTHENTICATE_USER, payload: res.data });
        dispatch(getMesAnnonce({userId: res?.data?.user?.id}))
      } else {
        const error = res.data.errors;
        if (error) {
          throw new Error(error);
        }
      }
    })
    .catch((error) => {
      throw error; // Rethrow the error for better error propagation
    });
};


export const loginUser = (params) => async (dispatch) => {
  try {
    const res = await apiClient().post(`/login`, params);
    
    if (res.data.success === true) {
      dispatch({ type: AUTHENTICATE_USER, payload: res.data });
      
      // Register FCM after successful login
      try {
        FCMService.registerDevice(res.data.user.id);
      } catch (fcmError) {
        console.error('FCM registration failed:', fcmError);
        // Don't block login if FCM fails
      }

      // Send to WebView
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'USER_LOGIN',
          userData: {
            id: res.data.user.id,
          }
        }));
      }
      
      dispatch(getMesAnnonce({userId: res?.data?.user?.id}));
    } else {
      console.error('Login failed:', res.data);
      throw new Error("l'email ou le mot de passe est incorrect");
    }
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logoutFunct = () => async (dispatch) => {
  try {
    if (!window.ReactNativeWebView) {
      // Unregister FCM before logout
      FCMService.unregisterDevice();
      
    }
    
    
    dispatch({ type: AUTHENTICATE_USER, payload: '' });
    
    // Send to WebView
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'USER_LOGOUT',
      }));
    }
  } catch (error) {
    console.error('Logout error:', error);
  }
};

export const getListDep = (params) => (dispatch) => {
  const data = {
    ...params,
  };

  return apiClient()
    .post(`/get-users-distance`, data) //change this /membre/login
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: LIST_DEP, payload: res.data.users });
      } 
    });
};


export const save_insc_name = (name) => (dispatch) => {
  dispatch({type: INSC_NAME, payload: name})
}

export const save_insc_phone = (phone) => (dispatch) => {
  dispatch({type: INSC_PHONE, payload: phone})
}

export const save_insc_email = (email) => (dispatch) => {
  dispatch({type: INSC_EMAIL, payload: email})

}

export const save_insc_country = (country) => (dispatch) => { 
  dispatch({type: INSC_COUNTRY, payload: country})
}

export const save_insc_code = (code) => (dispatch) => {
  dispatch({type: INSC_CODE, payload: code})
}

export const save_insc_password = (password) => (dispatch) => {
  dispatch({type: INSC_PASSWORD, payload: password})
} 

