import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./styles.scss";
import { PositionSwipe, RateDrawer, SwipeComp } from "../../components";
import { PurpleMarker } from "../../assets/images";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IS_SIDE_BAR } from "../../constants/actions";
import L from "leaflet";
import PlaceIcon from "../../assets/images/geoplace.png";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import { MapContainer, TileLayer, Marker, Popup, useMapEvent } from "react-leaflet";
import axios from "axios";
import { getListDep } from "../../actions/user";
import { GoogleMap, useLoadScript, CircleF, useJsApiLoader, MarkerF  } from "@react-google-maps/api";
import {getGeocode} from "use-places-autocomplete";
import { apiClient } from "../../actions/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { googleMapsConfig } from '../../config/googleMapsConfig';

export default function GetDepPage() {

  const [showdepp, setShowDepp] = useState(false);
  const [userLocation, setUserLocation] = useState({lat: 5.345317, lng: -4.024429});
  const [address, setAddress] = useState("");
  const [gotet, setgotet] = useState(false);
  const { typechoice, ratedrawer } = useSelector((state) => state.global)
  const { user } = useSelector((state) => state.user);
  const [mapKey, setMapKey] = useState(0);
  const [depannersList, setDepannersList] = useState([]);
  const [isWaitingModal, setIsWaitingModal] = useState(false);
  const [requestId, setRequestId] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  
  const containerStyle = {
    width: '100%',
    height: '100vh'
  };
  
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523
  });

  useEffect(() => {
    if (userLocation.lat !== 5.345317 && userLocation.lng !== -4.024429) {
      // dispatch(getListDep({ lat: userLocation?.lat, lng: userLocation?.lng }));
      apiClient()
      .post(`/get-users-distance`, { lat: userLocation?.lat, lng: userLocation?.lng }) //change this /membre/login
      .then((res) => {
        if (res.data.success) {
          setDepannersList(res.data.users);
        } 
      });
    } else {
      apiClient()
      .post(`/get-users-distance`, {lat: 5.345317, lng: -4.024429}) //change this /membre/login
      .then((res) => {
        if (res.data.success) {
          setDepannersList(res.data.users);
        } 
      });
    }
  }, []);

  
  useEffect(() => {
      setCenter(userLocation)
  }, [userLocation])

  const { isLoaded } = useJsApiLoader(googleMapsConfig);
  
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(17); 
    setMap(map);
  }, [center]);
  
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // Add function to handle mobile location
  const handleMobileLocation = useCallback((event) => {
    const location = event.detail;
    if (location) {
      setUserLocation(location);
      setCenter(location);
      
      // Fetch depanneurs when location is received
      apiClient()
        .post(`/get-users-distance`, location)
        .then((res) => {
          if (res.data.success) {
            setDepannersList(res.data.users);
          }
          setShowDepp(true);
        })
        .catch(error => {
          console.error("Error fetching depanners:", error);
          setShowDepp(true);
        });

      // Update address
      getGeocode({ location })
        .then((results) => {
          if (results?.[0]?.formatted_address) {
            setAddress(results[0].formatted_address);
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error.message);
        });
    }
  }, []);

  // Listen for mobile location updates
  useEffect(() => {
    // Check if running in WebView
    const isInWebView = !!window.ReactNativeWebView;

    if (isInWebView) {
      // Add listener for mobile location
      window.addEventListener('mobileLocation', handleMobileLocation);
      
      return () => {
        window.removeEventListener('mobileLocation', handleMobileLocation);
      };
    } else {
      // Regular web browser location handling
      if (!navigator.geolocation) {
        console.error('Geolocation is not supported');
        return;
      }

      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const newLocation = { lat: latitude, lng: longitude };
          setUserLocation(newLocation);
          setCenter(newLocation);

          // Update address when getting location
          try {
            const results = await getGeocode({ location: newLocation });
            if (results?.[0]?.formatted_address) {
              setAddress(results[0].formatted_address);
            }
          } catch (error) {
            console.error("Error fetching address:", error.message);
          }
        },
        (error) => {
          console.error("Error getting user's location:", error.message);
          const defaultLocation = {lat: 5.345317, lng: -4.024429};
          setUserLocation(defaultLocation);
          setCenter(defaultLocation);
          
          // Set default address
          getGeocode({ location: defaultLocation })
            .then((results) => {
              if (results?.[0]?.formatted_address) {
                setAddress(results[0].formatted_address);
              }
            })
            .catch((error) => {
              console.error("Error fetching address:", error.message);
            });
        },
        options
      );
    }
  }, [handleMobileLocation]);

  const handleBack = (e) => {
    e.preventDefault();
    if(showdepp){
      setShowDepp(false);
    } else {
      dispatch({ type: IS_SIDE_BAR, payload: false });
      history.push("/showall/"+typechoice)
    }
  };

  // Modify handleFindDepanneur to work with WebView
  const handleFindDepanneur = () => {
    const isInWebView = !!window.ReactNativeWebView;

    if (isInWebView) {
      // Request location from mobile app
      window.postMessage('getLocation');
      console.log("user location in if", userLocation)
    } else {
      console.log("user location in else", userLocation)
      // Use current userLocation state to fetch depanneurs
      apiClient()
        .post(`/get-users-distance`, userLocation)
        .then((res) => {
          if (res.data.success) {
            setDepannersList(res.data.users);
          }
          setShowDepp(true);
          console.log("user location", userLocation)
        })
        .catch(error => {
          console.error("Error fetching depanners:", error);
          setShowDepp(true);
        });
    }
  };

  // Add this function to check request status
  const checkRequestStatus = useCallback((id) => {
    if (!id) {
      console.error("No request ID provided to checkRequestStatus");
      return;
    }
    
    console.log("Checking status for request:", id);
    
    apiClient()
      .get(`/assignment/check-request-status/${id}`)
      .then((res) => {
        console.log("Status response:", res.data);
        if (res.data.status === 'accepted') {
          setIsWaitingModal(false);
          toast.success("Le dépanneur a accepté votre demande!");
          // Pass the assignment ID in the URL
          history.push(`/active-request-client/${id}`);
        } else if (res.data.status === 'rejected') {
          setIsWaitingModal(false);
          toast.error("Le dépanneur a refusé votre demande.");
        }
      })
      .catch(error => {
        console.error("Error checking status:", error);
      });
  }, [history]);

  // Modify the handleChooseDepanneur function
  const handleChooseDepanneur = (depanneurId) => {
    const clientData = {
      depanneurId: depanneurId,
      clientLocation: userLocation,
      clientAddress: address,
      clientName: user?.user?.firstName + ' ' + user?.user?.lastName || 'Anonymous Client',
      clientId: user?.user?.id,
      clientPhone: user?.user?.phoneNumber,
      status: 'pending'
    };

    apiClient()
      .post('/assignment/assign-client', clientData)
      .then((res) => {
        if (res.data.success) {
          setRequestId(res.data.assignment.id);
          toast.info("Demande envoyée au dépanneur, en attente d'acceptation");
          setIsWaitingModal(true);
          // Start checking status immediately
          checkRequestStatus(res.data.assignment.id);
        }
      })
      .catch(error => {
        console.error("Error assigning client:", error);
        if (error.response?.data?.message === "Depanneur already has a pending assignment") {
          toast.warning("Ce dépanneur n'est pas disponible pour le moment. Veuillez en choisir un autre.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error("Une erreur s'est produite. Veuillez réessayer.");
        }
      });
  };

  // Modify the useEffect to start immediately and then continue polling
  useEffect(() => {
    let interval;
    if (isWaitingModal && requestId) {
      // Check immediately when modal opens
      checkRequestStatus(requestId);
      
      // Then set up polling every 6 seconds
      interval = setInterval(() => {
        checkRequestStatus(requestId);
      }, 6000);
    }
    
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isWaitingModal, requestId, checkRequestStatus]);

  const handleCancelRequest = () => {
    if (requestId) {
      apiClient()
        .post('/assignment/cancel-request', { requestId })
        .then(() => {
          setIsWaitingModal(false);
          toast.info("Demande annulée");
        })
        .catch(error => {
          console.error("Error canceling request:", error);
          toast.error("Erreur lors de l'annulation");
        });
    }
  };

  return (
    <div className="get-dep-container" id="map">
      <ToastContainer />
      <div className="map-google">
            {isLoaded ? (
              <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
              onLoad={onLoad}
              onUnmount={onUnmount}
              key={mapKey}
              options={{
                streetViewControl: false,
                zoomControl: true,
                fullscreenControl: false,
                mapTypeControl: false,
              }}
            >
              <CircleF
                  center={center}
                  radius={100}
                  options={{ fillColor: "#673ab7", strokeColor: "transparent" }}
                />
              <MarkerF position={{lat: Number(center?.lat), lng: Number(center?.lng)}}  />
            </GoogleMap>
            ) : null}
          </div>
      <div className="contentDep">
      {ratedrawer && <RateDrawer />}
      {showdepp ? (
        <>
          {" "}
          <div className="get-dep-head">
            <UilAngleLeft
              size="35"
              color="#6a02c6"
              onClick={(e) => handleBack(e)}
            />
            <div className="position-div-top">
              <img src={PurpleMarker} alt="" />

              <div className="spans-div">
                <span>Vous êtes ici</span>
                <span className="adress-text">{address}</span>
              </div>
            </div>
          </div>
          <SwipeComp 
            depList={depannersList} 
            onChooseDepanneur={handleChooseDepanneur} 
          />
        </>
      ) : (
        <>
          <div className="get-dep-head" id="pos-head">
            <UilAngleLeft
              size="35"
              color="#6a02c6"
              onClick={handleBack}
            />
          </div>
          <PositionSwipe 
            vousou={address} 
            showdepp={handleFindDepanneur}
          />
        </>
      )}
      </div>

      {isWaitingModal && (
        <div className="custom-modal-overlay">
          <div className="custom-modal">
            <div className="modal-content">
              <div className="loader"></div>
              <h2>En attente de confirmation</h2>
              <p>Le dépanneur est en train de vérifier votre demande...</p>
              <button 
                className="cancel-btn"
                onClick={handleCancelRequest}
              >
                Annuler la demande
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}