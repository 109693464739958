import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, useJsApiLoader, MarkerF, DirectionsRenderer } from "@react-google-maps/api";
import { apiClient } from "../../actions/api";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { toast } from 'react-toastify';
import { googleMapsConfig } from '../../config/googleMapsConfig';

export default function ActiveRequestClient() {
  const [assignment, setAssignment] = useState(null);
  const [directions, setDirections] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const initialFetchDone = React.useRef(false);

  const { isLoaded } = useJsApiLoader(googleMapsConfig);

  const containerStyle = {
    width: '100%',
    height: '100vh'
  };

  // Add the helper function to validate coordinates
  const isValidCoordinate = (coord) => {
    return coord && 
           !isNaN(coord.lat) && 
           !isNaN(coord.lng) && 
           coord.lat !== 0 && 
           coord.lng !== 0 && 
           coord.lat >= -90 && 
           coord.lat <= 90 && 
           coord.lng >= -180 && 
           coord.lng <= 180;
  };

  // Fetch assignment data
  const fetchAssignment = useCallback(() => {
    apiClient()
      .get(`/assignment/${id}`)
      .then((res) => {
        if (res.data.success) {
          setAssignment(res.data.assignment);
          updateDirections(res.data.assignment);
        }
      })
      .catch(error => {
        console.error("Error fetching assignment:", error);
        toast.error("Erreur lors de la récupération des données");
      });
  }, [id]);

  // Update directions when assignment data changes
  const updateDirections = useCallback((assignmentData) => {
    if (!window.google) {
      console.warn('Google Maps not loaded yet');
      return;
    }

    // Log the coordinates being used
    console.log('Updating directions with:', {
      origin: {
        lat: Number(assignmentData.client_location_lat),
        lng: Number(assignmentData.client_location_lng)
      },
      destination: {
        lat: Number(assignmentData.depanneur_location_lat),
        lng: Number(assignmentData.depanneur_location_lng)
      }
    });

    // Validate coordinates
    const origin = {
      lat: Number(assignmentData.client_location_lat),
      lng: Number(assignmentData.client_location_lng)
    };

    const destination = {
      lat: Number(assignmentData.depanneur_location_lat),
      lng: Number(assignmentData.depanneur_location_lng)
    };

    // Check if coordinates are valid
    if (!isValidCoordinate(origin) || !isValidCoordinate(destination)) {
      console.warn('Invalid coordinates detected:', { origin, destination });
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK") {
          setDirections(result);
        } else {
          console.warn(`Directions request failed: ${status}`, {
            origin,
            destination
          });
          // Clear previous directions if the request fails
          setDirections(null);
        }
      }
    );
  }, []);

  // Add function to handle mobile location
  const handleMobileLocation = useCallback((event) => {
    const location = event.detail;
    if (location) {
      // Update directions with new location data
      if (assignment) {
        updateDirections({
          ...assignment,
          depanneur_location_lat: location.lat,
          depanneur_location_lng: location.lng
        });
      }
    }
  }, [assignment]);

  // Modify useEffect to handle both WebView and browser cases
  useEffect(() => {
    // Check if running in WebView
    const isInWebView = !!window.ReactNativeWebView;
    console.log('Environment:', isInWebView ? 'WebView' : 'Web', {
      googleLoaded: !!window.google,
      assignment: !!assignment
    });

    // Common fetch function for both environments
    const fetchData = async () => {
      try {
        const response = await apiClient().get(`/assignment/${id}`);
        if (response.data.success) {
          setAssignment(response.data.assignment);
          if (window.google) {
            updateDirections(response.data.assignment);
          }
        }
      } catch (error) {
        console.error("Error fetching assignment:", error);
        toast.error("Erreur lors de la récupération des données");
      }
    };

    if (isInWebView) {
      // Notify the mobile app that the web view is ready
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'WEBVIEW_READY'
        }));
      }

      window.addEventListener('mobileLocation', handleMobileLocation);
      
      // Initial fetch and polling for WebView
      if (!initialFetchDone.current) {
        fetchData();
        initialFetchDone.current = true;
      }

      // Set up polling interval with a longer delay for WebView
      const interval = setInterval(fetchData, 15000); // 15 seconds for WebView
      
      return () => {
        window.removeEventListener('mobileLocation', handleMobileLocation);
        clearInterval(interval);
      };
    } else {
      // Web browser initial fetch and polling
      if (!initialFetchDone.current) {
        fetchData();
        initialFetchDone.current = true;
      }

      // Set up polling interval for web browser
      const interval = setInterval(fetchData, 10000); // 10 seconds for web

      return () => {
        clearInterval(interval);
      };
    }
  }, [id, handleMobileLocation, updateDirections, fetchAssignment]);

  // Add debug logging for assignment changes
  useEffect(() => {
    console.log('Assignment updated:', assignment);
  }, [assignment]);

  // Add debug logging for directions changes
  useEffect(() => {
    console.log('Directions updated:', !!directions);
  }, [directions]);

  const handleBack = () => {
    history.goBack();
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="active-request-container">
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={assignment ? {
            lat: Number(assignment.client_location_lat),
            lng: Number(assignment.client_location_lng)
          } : undefined}
          zoom={15}
          options={{
            streetViewControl: false,
            zoomControl: true,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
        >
          {assignment && (
            <>
              {/* Client Marker */}
              <MarkerF
                position={{
                  lat: Number(assignment.client_location_lat),
                  lng: Number(assignment.client_location_lng)
                }}
                label="C"
              />
              {/* Depanneur Marker */}
              <MarkerF
                position={{
                  lat: Number(assignment.depanneur_location_lat),
                  lng: Number(assignment.depanneur_location_lng)
                }}
                label="D"
              />
              {/* Show directions */}
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeColor: "#6a02c6",
                      strokeWeight: 5,
                    },
                  }}
                />
              )}
            </>
          )}
        </GoogleMap>
      </div>

      <div className="info-overlay">
        <button className="back-button" onClick={handleBack}>
          <UilAngleLeft size="24" color="#6a02c6" />
          Retour
        </button>
        
        {assignment && (
          <div className="status-card">
            <h2>Dépanneur en route</h2>
            <div className="status-details">
              <p>Dépanneur: {assignment.depanneur_name}</p>
              <p>Distance estimée: {directions?.routes[0]?.legs[0]?.distance?.text || 'Calcul...'}</p>
              <p>Temps estimé: {directions?.routes[0]?.legs[0]?.duration?.text || 'Calcul...'}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 